import * as React from 'jsx-dom';
import {unwrap} from 'core/helpers/dom';

interface BaseProps {
  text: string;
  classes?: string;
  analytics?: Record<string, string | boolean>;
}

interface FakeArrowLink extends BaseProps {
  semantic: 'none';
}

export interface ArrowLinkProps extends BaseProps {
  link: string;
  rel?: string;
  target?: string;
  semantic?: 'a';
}

export interface ButtonProps extends BaseProps {
  semantic: 'button';
  type?: 'button' | 'submit' | 'reset';
}

//TODO: Unify all ArrowLinks entities!
export function renderArrowLink(props: ArrowLinkProps): HTMLAnchorElement;
export function renderArrowLink(props: ButtonProps): HTMLButtonElement;
export function renderArrowLink(props: FakeArrowLink): HTMLDivElement;
export function renderArrowLink(props: ArrowLinkProps | ButtonProps | FakeArrowLink): HTMLAnchorElement | HTMLButtonElement | HTMLDivElement {
  const {analytics, classes, semantic} = props;
  const words = (props.text || '').trim().split(' ');
  const lastWord = words.pop();
  const content = (
    <span className="link-text">
      {words.length ? `${words.join(' ')} ` : ''}
      <span className="arrow-wrapper">
            {lastWord}
        <div className="arrow"/>
      </span>
    </span>);

  //typography type is defined in classes
  if (semantic === 'button') {
    return (
      <button type={props.type} className={`hpe-arrow-link no-js ${classes || ''}`} {...analytics}>
        {content}
      </button>) as HTMLButtonElement;
  }

  if (semantic === 'none') {
    return (
      <div className={`hpe-arrow-link no-js ${classes || ''}`} {...analytics}>
        {content}
      </div>) as HTMLDivElement;
  }

  const {link, target, rel} = props;
  return (
    <a className={`hpe-arrow-link no-js ${classes || ''}`}
       href={link} rel={rel} target={target} {...analytics}>
      {content}
    </a>
  ) as HTMLAnchorElement;
}

export const ArrowLink = renderArrowLink;

interface ArrowLinksElement extends HTMLElement {
  __arrowLastWord?: boolean;
}

export const processArrowLink = (el: ArrowLinksElement): void => {
  const a = unwrap(el);
  if (a && !a.__arrowLastWord) {
    const words: string[] = (a.textContent || '').trim().split(' ');
    const lastWord: string = words.pop();
    a.innerHTML = `${words.join(' ')} <span class="arrow-wrapper">${lastWord}<div class="arrow"></div></span>`;
    a.__arrowLastWord = true;
  }
};

export default {
  processArrowLink,
  initialize: () => Array.from(document.querySelectorAll('.hpe-arrow-link:not(.no-js)')).forEach(processArrowLink)
};
