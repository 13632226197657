import * as React from 'jsx-dom';

import {ESLToggleable, ESLToggleableActionParams} from '@exadel/esl/modules/esl-toggleable/core';
import {lockScroll, unlockScroll} from '@exadel/esl/modules/esl-utils/dom/scroll';
import {attr, boolAttr, prop, memoize, listen} from '@exadel/esl/modules/esl-utils/decorators';
import {i18nLabel} from 'core/helpers/config';
import {ESC, TAB} from '@exadel/esl/modules/esl-utils/dom/keys';
import {getKeyboardFocusableElements, handleFocusChain} from '@exadel/esl/modules/esl-utils/dom/focus';

import type {ScrollLockOptions} from '@exadel/esl/modules/esl-utils/dom/scroll/utils';
import type {ESLMedia} from '@exadel/esl/modules/esl-media/core';
import type {DelegatedEvent} from '@exadel/esl/modules/esl-event-listener/core/types';

export type ScrollLockStrategies = ScrollLockOptions['strategy'];

/**
 * HPE base modal web component
 * */
export class BaseModal extends ESLToggleable {
  static is = 'base-modal';
  static readonly buildModal = buildModal;
  static readonly openStack: BaseModal[] = [];

  @prop() public closeOnEsc = true;
  @prop() public closeOnOutsideAction = true;

  @attr({defaultValue: `.${BaseModal.is}-container`})
  public dialogArea: string;

  @attr({defaultValue: '[data-modal-close]'})
  public closeTrigger: string;

  @attr({defaultValue: 'background'})
  public scrollLockStrategy: ScrollLockStrategies;

  @attr({defaultValue: 'video-overlay, .esl-popup, esl-select-dropdown'})
  public ignored: string;

  @boolAttr() public noBackdrop: boolean;
  @boolAttr() public autoInject: boolean;
  @boolAttr() public videoAutoPlay: boolean;

  protected connectedCallback(): void {
    super.connectedCallback();
    this.setAttribute('role', 'dialog');
    // To catch keyboard event target properly
    if (!this.hasAttribute('tabindex')) this.tabIndex = -1;
  }

  protected setInitialState() {}

  public get lockOptions(): ScrollLockOptions {
    return {
      strategy: this.scrollLockStrategy,
      initiator: this
    };
  }

  @memoize()
  protected get $dialog(): HTMLElement {
    return this.querySelector(this.dialogArea) as HTMLElement;
  }

  @memoize()
  public get $players(): ESLMedia[] {
    return Array.from(this.querySelectorAll('smart-media'));
  }

  @memoize()
  protected get $backdrop(): HTMLElement {
    return (<div className="base-modal-backdrop" data-modal-close={true}/>) as HTMLElement;
  }

  public get $boundaryFocusable(): {first: HTMLElement, last: HTMLElement} {
    const visibleEls = getKeyboardFocusableElements(this) as HTMLElement[];
    return {first: visibleEls[0], last: visibleEls.pop()};
  }

  protected get stack(): BaseModal[] {
    return (this.constructor as typeof BaseModal).openStack;
  }

  protected override onShow(params: ESLToggleableActionParams): void {
    this.stack.push(this);
    this.noBackdrop ? this.removeBackdrop() : this.attachBackdrop();
    if (this.autoInject) this.inject();
    this.activator = params.activator;
    super.onShow(params);
    this.focus();
    lockScroll(document.documentElement, this.lockOptions);
    this.triggerPlayers();
  }

  protected override onHide(params: ESLToggleableActionParams = {}): void {
    const index = this.stack.indexOf(this);
    if (index !== -1) this.stack.splice(index, 1);
    super.onHide(params);
    this.triggerPlayers();
    unlockScroll(document.documentElement, this.lockOptions);
    this.activator?.focus({preventScroll: true});
    if (this.autoInject) this.extract();
  }

  protected inject(): void {
    if (this.parentNode === document.body) return;
    document.body.appendChild(this);
  }

  protected extract(): void {
    if (this.parentNode !== document.body) return;
    document.body.removeChild(this);
  }

  protected attachBackdrop(): void {
    if (this.$backdrop.parentElement === this) return;
    this.appendChild(this.$backdrop);
  }

  protected removeBackdrop(): void {
    if (this.$backdrop.parentElement !== this) return;
    this.removeChild(this.$backdrop);
  }

  protected triggerPlayers(): void {
    if (!this.videoAutoPlay) return;
    const isOpen = this.open;
    this.$players.forEach((player: ESLMedia) => (isOpen ? player.play() : player.pause()));
  }

  @listen({inherit: true})
  protected override _onCloseClick(e: DelegatedEvent<MouseEvent>): void {
    const initiator = e.$delegate.getAttribute('data-modal-close') || 'close';
    this.hide({ initiator, activator: e.$delegate as HTMLElement, event: e });
    e.stopPropagation();
  }

  @listen({inherit: true})
  protected override _onKeyboardEvent(e: KeyboardEvent): void {
    if (e.key === TAB) this._onTabKey(e);
    if (this.closeOnEsc && e.key === ESC) {
      this.hide({initiator: 'keyboard', event: e});
      e.stopPropagation();
    }
  }

  protected _onTabKey(e: KeyboardEvent): boolean | undefined {
    const {first, last} = this.$boundaryFocusable;
    return handleFocusChain(e, first as HTMLElement, last as HTMLElement);
  }

  public override isOutsideAction(e: Event): boolean {
    if ((e.target as HTMLElement).closest(this.ignored)) return;
    if (this.stack[this.stack.length - 1] !== this) return false;
    return super.isOutsideAction(e);
  }
}

export interface ModalConfig {
  className?: string;
  contentClassName?: string;
  header?: string;
  content: string | HTMLElement | JSX.Element;
  analytics?: string;
  closeBtnAnalytics?: string;
}

function buildModal(config: ModalConfig): BaseModal {
  const {className, contentClassName, header, content, analytics, closeBtnAnalytics} = config;

  return (
    <base-modal auto-inject className={`typo4 ${className || ''}`} body-class="base-modal-opened" data-analytics-region-id={analytics} tabIndex={-1}>
      <div className={`base-modal-container ${contentClassName || ''}`}>
        {header ? <div className="body-copy-small rich-text-container" innerHTML={header}/> : ''}
        {content}
        <button type="button"
                className="close-btn icon-nav-close-menu"
                aria-label={i18nLabel('Close')}
                title={i18nLabel('Close')}
                data-analytics-region-id={closeBtnAnalytics}
                data-modal-close/>
      </div>
    </base-modal>) as BaseModal;
}

export default {
  initialize: () => BaseModal.register()
};
