var MODULE_ALIAS = 'bundles/compilers/unicorn.compiler'; 
import {createCompiler} from 'core/core';
import {initComponent} from 'core/helpers/component';

export const compile = createCompiler('unicorn', ($) => {
 initComponent.$if($('.spec-module')) && require.ensure('unicorn/specification',
            function(require) { initComponent.$if($('.spec-module')) && initComponent(require('unicorn/specification'), $('.spec-module'));;},
            function(error) { require('core/log').error("Can't load unicorn/specification", error); },
            'unicorn/specification'
            );
 initComponent.$if($('.js-our-customers')) && require.ensure('unicorn/our-customers',
            function(require) { initComponent.$if($('.js-our-customers')) && initComponent(require('unicorn/our-customers'), $('.js-our-customers'));;},
            function(error) { require('core/log').error("Can't load unicorn/our-customers", error); },
            'unicorn/our-customers'
            );
 initComponent.$if($('image-modal')) && require.ensure('unicorn/image-modal',
            function(require) { initComponent.$if($('image-modal')) && initComponent(require('unicorn/image-modal'), $('image-modal'));;},
            function(error) { require('core/log').error("Can't load unicorn/image-modal", error); },
            'unicorn/image-modal'
            );
 initComponent.$if($('compare-table')) && require.ensure('unicorn/compare-table',
            function(require) { initComponent.$if($('compare-table')) && initComponent(require('unicorn/compare-table'), $('compare-table'));;},
            function(error) { require('core/log').error("Can't load unicorn/compare-table", error); },
            'unicorn/compare-table'
            );
 initComponent.$if($('tab-container')) && require.ensure('unicorn/tab-container',
            function(require) { initComponent.$if($('tab-container')) && initComponent(require('unicorn/tab-container'), $('tab-container'));;},
            function(error) { require('core/log').error("Can't load unicorn/tab-container", error); },
            'unicorn/tab-container'
            );
 initComponent.$if($('branded-links')) && require.ensure('unicorn/branded-links',
            function(require) { initComponent.$if($('branded-links')) && initComponent(require('unicorn/branded-links'), $('branded-links'));;},
            function(error) { require('core/log').error("Can't load unicorn/branded-links", error); },
            'unicorn/branded-links'
            );
 initComponent.$if($('uc-agenda')) && require.ensure('unicorn/agenda',
            function(require) { initComponent.$if($('uc-agenda')) && initComponent(require('unicorn/agenda'), $('uc-agenda'));;},
            function(error) { require('core/log').error("Can't load unicorn/agenda", error); },
            'unicorn/agenda'
            );
 initComponent.$if($('.tab-grid')) && require.ensure('unicorn/tab-grid-target',
            function(require) { initComponent.$if($('.tab-grid')) && initComponent(require('unicorn/tab-grid-target'), $('.tab-grid'));;},
            function(error) { require('core/log').error("Can't load unicorn/tab-grid-target", error); },
            'unicorn/tab-grid-target'
            );
 initComponent.$if($('hpe-countdown')) && require.ensure('unicorn/countdown',
            function(require) { initComponent.$if($('hpe-countdown')) && initComponent(require('unicorn/countdown'), $('hpe-countdown'));;},
            function(error) { require('core/log').error("Can't load unicorn/countdown", error); },
            'unicorn/countdown'
            );
 initComponent.$if($('video-gallery')) && require.ensure('unicorn/video-gallery',
            function(require) { initComponent.$if($('video-gallery')) && initComponent(require('unicorn/video-gallery'), $('video-gallery'));;},
            function(error) { require('core/log').error("Can't load unicorn/video-gallery", error); },
            'unicorn/video-gallery'
            );
 initComponent.$if($('uc-table')) && require.ensure('unicorn/table',
            function(require) { initComponent.$if($('uc-table')) && initComponent(require('unicorn/table'), $('uc-table'));;},
            function(error) { require('core/log').error("Can't load unicorn/table", error); },
            'unicorn/table'
            );
 initComponent.$if($('hpe-takeover')) && require.ensure('unicorn/takeover',
            function(require) { initComponent.$if($('hpe-takeover')) && initComponent(require('unicorn/takeover'), $('hpe-takeover'));;},
            function(error) { require('core/log').error("Can't load unicorn/takeover", error); },
            'unicorn/takeover'
            );
 initComponent.$if($('hpe-video-marquee')) && require.ensure('unicorn/video-marquee',
            function(require) { initComponent.$if($('hpe-video-marquee')) && initComponent(require('unicorn/video-marquee'), $('hpe-video-marquee'));;},
            function(error) { require('core/log').error("Can't load unicorn/video-marquee", error); },
            'unicorn/video-marquee'
            );
 initComponent.$if($('hpe-numeric-callout-item')) && require.ensure('unicorn/numeric-callout-item',
            function(require) { initComponent.$if($('hpe-numeric-callout-item')) && initComponent(require('unicorn/numeric-callout-item'), $('hpe-numeric-callout-item'));;},
            function(error) { require('core/log').error("Can't load unicorn/numeric-callout-item", error); },
            'unicorn/numeric-callout-item'
            );
 initComponent.$if($('media-browser')) && require.ensure('unicorn/media-browser-init-strategy',
            function(require) { initComponent.$if($('media-browser')) && initComponent(require('unicorn/media-browser-init-strategy'), $('media-browser'));;},
            function(error) { require('core/log').error("Can't load unicorn/media-browser-init-strategy", error); },
            'unicorn/media-browser-init-strategy'
            );
 initComponent.$if($('hpe-assessment')) && require.ensure('unicorn/assessment',
            function(require) { initComponent.$if($('hpe-assessment')) && initComponent(require('unicorn/assessment'), $('hpe-assessment'));;},
            function(error) { require('core/log').error("Can't load unicorn/assessment", error); },
            'unicorn/assessment'
            );
 initComponent.$if($('hpe-animated-loader')) && require.ensure('unicorn/animated-loader',
            function(require) { initComponent.$if($('hpe-animated-loader')) && initComponent(require('unicorn/animated-loader'), $('hpe-animated-loader'));;},
            function(error) { require('core/log').error("Can't load unicorn/animated-loader", error); },
            'unicorn/animated-loader'
            );
 initComponent.$if($('hpe-cs-draggable')) && require.ensure('unicorn/customer-stories',
            function(require) { initComponent.$if($('hpe-cs-draggable')) && initComponent(require('unicorn/customer-stories'), $('hpe-cs-draggable'));;},
            function(error) { require('core/log').error("Can't load unicorn/customer-stories", error); },
            'unicorn/customer-stories'
            );
 initComponent.$if($('showcase-marquee')) && require.ensure('unicorn/showcase-marquee',
            function(require) { initComponent.$if($('showcase-marquee')) && initComponent(require('unicorn/showcase-marquee'), $('showcase-marquee'));;},
            function(error) { require('core/log').error("Can't load unicorn/showcase-marquee", error); },
            'unicorn/showcase-marquee'
            );
 initComponent.$if($('spotlight-resources')) && require.ensure('unicorn/spotlight-resources',
            function(require) { initComponent.$if($('spotlight-resources')) && initComponent(require('unicorn/spotlight-resources'), $('spotlight-resources'));;},
            function(error) { require('core/log').error("Can't load unicorn/spotlight-resources", error); },
            'unicorn/spotlight-resources'
            );
 initComponent.$if($('[animated-nav]')) && require.ensure('core/animated-nav-mixin',
            function(require) { initComponent.$if($('[animated-nav]')) && initComponent(require('core/animated-nav-mixin'), $('[animated-nav]'));;},
            function(error) { require('core/log').error("Can't load core/animated-nav-mixin", error); },
            'core/animated-nav-mixin'
            );
 initComponent.$if($('timeline-component')) && require.ensure('unicorn/timeline',
            function(require) { initComponent.$if($('timeline-component')) && initComponent(require('unicorn/timeline'), $('timeline-component'));;},
            function(error) { require('core/log').error("Can't load unicorn/timeline", error); },
            'unicorn/timeline'
            );
});
