var MODULE_ALIAS = 'bundles/compilers/core.compiler'; 
import {createCompiler} from 'core/core';
import {isAEMAuthorMode} from 'core/helpers/aem';
import {initComponent} from 'core/helpers/component';

export const compile = createCompiler('core', ($) => {
  // Handles focus related behaviour
  initComponent(require('core/focus-states'));

  // Init Privacy Banner
  initComponent(require('core/hpe-privacy-banner'));

  // Anchor Custom Behaviour
  initComponent(require('core/anchor-scroller'));
  initComponent.$if($('[hpe-track-anchor]')) && initComponent(require('core/hpe-track-anchor-mixin'), $('[hpe-track-anchor]'));

  // TODO: check if everything is needed on all pages
  /* CORE */
  initComponent(require('core/auto-i18n-collect'), null, $);
  initComponent.$if($('.js-embedded-video')) && initComponent(require('core/video'), $('.js-embedded-video'));
  initComponent.$if($('.js-carousel')) && initComponent(require('core/carousel'), $('.js-carousel'));
  initComponent.$if($('.js-slide-carousel')) && initComponent(require('core/slide.carousel'), $('.js-slide-carousel'));
  initComponent.$if($('[data-animate]')) && initComponent(require('core/animate'), $('[data-animate]'));
  initComponent(require('core/video-initializer'), null, $);
  initComponent.$if($('.js-text-collapsible')) && initComponent(require('core/text-collapsible'), $('.js-text-collapsible'));
  initComponent(require('styleguide/hpe-card'), null, $);
  initComponent.$if($('.js-sticky-box')) && initComponent(require('core/sticky-box'), $('.js-sticky-box'));

  initComponent(require('design-3.0/hpe-pathfactory.init'));
  initComponent(require('core/privacy-tooltip'));

  // Auth Triggers
  initComponent(require('cockpit/helpers/login-iam-mixin'));
  initComponent(require('cockpit/helpers/logout-mixin'));

  /* Web Components Core helpers */
  initComponent(require('core/trigger-analytics'));
  initComponent(require('core/refresh-on-login'));

  /* PROCESSORS */
  initComponent(require('styleguide/arrow-link'));
  initComponent(require('design-3.0/click-to-call'));

  /* Test components */
  initComponent(require('styleguide/font-test'));

  /* Core common */
  initComponent(require('core/img-accent'));
  initComponent(require('core/gradient-mixin'));
  initComponent(require('core/base-modal'));
  initComponent(require('core/generic-modal'));
  initComponent(require('core/video-hva-tracker'));
  initComponent(require('core/hpe-compile-mixin'));
  initComponent(require('core/hpe-truncate-mixin'));
  initComponent(require('core/hpe-query-params-mixin'));
  initComponent(require('core/video-overlay-mixin'));
  initComponent(require('core/cta-group-mixin'));

  /* Async common */
 initComponent.$if($('media-wrapper')) && require.ensure('core/media-wrapper',
            function(require) { initComponent.$if($('media-wrapper')) && initComponent(require('core/media-wrapper'), $('media-wrapper'));;},
            function(error) { require('core/log').error("Can't load core/media-wrapper", error); },
            'core/media-wrapper'
            );

 initComponent.$if($('esl-carousel')) && require.ensure('core/esl-carousel',
            function(require) { initComponent.$if($('esl-carousel')) && initComponent(require('core/esl-carousel'), $('esl-carousel'));;},
            function(error) { require('core/log').error("Can't load core/esl-carousel", error); },
            'core/esl-carousel'
            );

 initComponent.$if($('[css-calc-mixin]')) && require.ensure('core/css-calc-mixin',
            function(require) { initComponent.$if($('[css-calc-mixin]')) && initComponent(require('core/css-calc-mixin'), $('[css-calc-mixin]'));;},
            function(error) { require('core/log').error("Can't load core/css-calc-mixin", error); },
            'core/css-calc-mixin'
            );
 initComponent.$if($('[css-lazy-bg]')) && require.ensure('core/css-lazy-bg-mixin',
            function(require) { initComponent.$if($('[css-lazy-bg]')) && initComponent(require('core/css-lazy-bg-mixin'), $('[css-lazy-bg]'));;},
            function(error) { require('core/log').error("Can't load core/css-lazy-bg-mixin", error); },
            'core/css-lazy-bg-mixin'
            );
 initComponent.$if($('[drag-to-scroll]')) && require.ensure('core/drag-to-scroll',
            function(require) { initComponent.$if($('[drag-to-scroll]')) && initComponent(require('core/drag-to-scroll'), $('[drag-to-scroll]'));;},
            function(error) { require('core/log').error("Can't load core/drag-to-scroll", error); },
            'core/drag-to-scroll'
            );
 initComponent.$if($('[match-height-for]')) && require.ensure('core/match-height-mixin',
            function(require) { initComponent.$if($('[match-height-for]')) && initComponent(require('core/match-height-mixin'), $('[match-height-for]'));;},
            function(error) { require('core/log').error("Can't load core/match-height-mixin", error); },
            'core/match-height-mixin'
            );
 initComponent.$if($('[print-trigger]')) && require.ensure('core/print-trigger-mixin',
            function(require) { initComponent.$if($('[print-trigger]')) && initComponent(require('core/print-trigger-mixin'), $('[print-trigger]'));;},
            function(error) { require('core/log').error("Can't load core/print-trigger-mixin", error); },
            'core/print-trigger-mixin'
            );

 initComponent.$if($('[art-text-parallax]')) && require.ensure('styleguide/art-text.parallax',
            function(require) { initComponent.$if($('[art-text-parallax]')) && initComponent(require('styleguide/art-text.parallax'), $('[art-text-parallax]'));;},
            function(error) { require('core/log').error("Can't load styleguide/art-text.parallax", error); },
            'styleguide/art-text.parallax'
            );

  /* AEM Utils */
  (async function () {
    if (!isAEMAuthorMode()) return;
    // eslint-disable-next-line
    await import (/* webpackChunkName: "core/aem-utils" */ 'core/aem-utils');
  })();
});
